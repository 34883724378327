const initialMapsState = {
  selectedPlace: {
    name: "Prishtina",
    category: "City",
    lng: 21.1719164,
    lat: 42.6556366,
    created: false,
    description:
      "Pristina is the capital and largest city of Kosovo. The city's municipal boundaries in Pristina District form the largest urban center in Kosovo. After Tirana, Pristina has the second largest population of ethnic Albanians and speakers of the Albanian language.",
  },
  selectedDrawer: false,
  newPlaceForm: false,
  allPlaces: [],
  userPlaces: [],
  editPlace: null,
  myPlaces: [],
};

const mapsActions = {
  MAPS_SET_ALL: "MAPS_SET_ALL",
  CLEAR_MAPS: "CLEAR_MAPS",
  MAPS_INITIAL_SELECTED_PLACE: "MAPS_INITIAL_SELECTED_PLACE",
};

function mapsReducer(state, action) {
  switch (action.type) {
    case mapsActions.MAPS_SET_ALL:
      return {
        ...state,
        ...action.payload,
      };
    case mapsActions.CLEAR_MAPS:
      return {
        ...initialMapsState,
      };
    case mapsActions.MAPS_INITIAL_SELECTED_PLACE:
      return {
        ...state,
        selectedPlace: {
          name: "Prishtina",
          category: "City",
          lng: 21.1719164,
          lat: 42.6556366,
          description:
            "Pristina is the capital and largest city of Kosovo. The city's municipal boundaries in Pristina District form the largest urban center in Kosovo. After Tirana, Pristina has the second largest population of ethnic Albanians and speakers of the Albanian language.",
        },
      };
    default:
      return state;
  }
}

export { initialMapsState, mapsActions, mapsReducer };